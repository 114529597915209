import Q from 'q'
import { createApiClient } from '../services/api-client'
import {UI_CONFIG} from '../config.js'
import RecentOrgCache from '../services/recent-org-cache'
import LicenseRequestService from '../services/license-request-service'
import SubscriptionUpdater from '../utils/subscription-updater'
import strUtils from 'smarttech-identity-string-utils'
import {cleanObject, entitlement, reseller} from '../utils/validate-utils'
import {BAD_REQUEST_ERROR, INTERNAL_ERROR} from '../enums/error-codes'
import { MENU_STATE } from '../enums/subscription-menu-state'
import {ErrorCodes} from '../enums'
import TranslationService from '../services/translation-service'
import {REQUEST_TYPE} from '../enums/admin-management-constants'
import environ from 'smarttech-identity-environment'
import { isEnabled } from '../services/feature-switch'
import { DEBUG_NON_STOCKING_CLAIM_FLOW, DEBUG_STOCKING_CLAIM_FLOW, FUSE_API, HYBRID_VIEW } from '../enums/feature-switch-constants'
import { sanitizeUserData } from '../utils/users-util.js'

let initializationPromise = null

export default {
    WaitOnApplicationInitialization (context) {
        if (!initializationPromise) {
            context.dispatch('InitializeApplication')
        }

        return initializationPromise
    },
    InitializeApplication (context) {
        context.commit('AppInitBegin')
        if (context.state.user.error) {
            // fail if the user is not signed in correctly
            initializationPromise = Q.reject({
                error: true,
                message: 'User is not signed in.'
            })
        } else {
            initializationPromise = Q.all([
                context.dispatch('FetchOrganizations'),
                context.dispatch('FetchSoftwarePortalStatus')
            ])

            initializationPromise.then(() => {
                console.log('app init\'d')
                context.commit('AppInitCompleted')
            })
        }

        return initializationPromise
    },

    FetchOrganizations ({commit}) {
        return new Promise((resolve, reject) => {
            let directOrgList = []
            let getAllDirectOrganizations = (nextToken) => {
                return createApiClient().getDirectOrganizations(nextToken)
                    .then((result) => {
                        directOrgList = directOrgList.concat(result.json)
                        if (result.nextToken) {
                            return getAllDirectOrganizations(result.nextToken)
                        }
                    })
            }

            let relatedOrgList = []

            // TODO: remove below function when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
            let getAllRelatedOrganizations = (nextToken) => {
                return createApiClient().getRelatedOrganizations(nextToken)
                    .then((result) => {
                        relatedOrgList = relatedOrgList.concat(result.json)
                        if (result.nextToken) {
                            return getAllRelatedOrganizations(result.nextToken)
                        }
                    })
            }
            if (isEnabled(HYBRID_VIEW) || isEnabled(FUSE_API)) {
                Q.all([createApiClient().getRecentOrganizations(), getAllDirectOrganizations()])
                .then((results) => {
                    // If we receive any related orgs in the /recent call, we should not show them. In the future, when we don't receive any, below line can be removed
                    const recentOrgs = results[0].json.filter(org => org.isDirect)
                    commit('InsertDirectOrganization', directOrgList)
                    commit('RecentOrganizationsReceived', recentOrgs)
                    commit('SelectedAllOrganizations')
                    resolve()
                }).catch(reject)
            } else {
                Q.all([createApiClient().getRecentOrganizations(), getAllDirectOrganizations(), getAllRelatedOrganizations()])
                .then((results) => {
                    commit('InsertDirectOrganization', directOrgList)
                    commit('InsertRelatedOrganization', relatedOrgList)
                    commit('RecentOrganizationsReceived', results[0].json)
                    commit('SelectedAllOrganizations')
                    resolve()
                }).catch(reject)
            }
        })
    },

    FetchSoftwarePortalStatus ({commit}) {
        return new Promise((resolve, reject) => {
            createApiClient().getStatus().then(results => {
                commit('StatusFetched', results.json)
                resolve()
            }).catch(reject)
        })
    },

    FetchUserSettings ({commit}) {
        return new Promise((resolve, reject) => {
            createApiClient().getUserSettings().then(results => {
                commit('UserSettingsFetched', results.json)
                resolve()
            }).catch(reject)
        })
    },

    UpdateUserSettings ({state, commit}, settingData) {
        const newSettings = Object.assign({}, state.userSettings, settingData)
        return new Promise((resolve, reject) => {
            createApiClient().updateUserSettings(newSettings).then(results => {
                commit('UserSettingsFetched', results.json)
                resolve()
            }).catch(reject)
        })
    },

    UpdateOnboardingStepOptimistically ({state, commit}, settingData) {
        const newSettings = Object.assign({}, state.userSettings, settingData)
        // We are doing an optimistic action for updating the onboarding step
        commit('UserSettingsOnboarding', settingData.manageUsersOnboardingStep)
        return new Promise((resolve, reject) => {
            createApiClient().updateUserSettings(newSettings).then(() => {
                resolve()
            }).catch(reject)
        })
    },

    SelectedOrganizationUpdated (context, org) {
        if (!org || !org.id) {
            context.commit('SelectedAllOrganizations')
            return
        }

        // Only allow one cache recent org request at
        // a time; otherwise, we can get server errors
        RecentOrgCache.cacheRecentOrg(org)

        let recent = context.state.organizations.recent

        // remove if there is a duplicate
        for (let i = 0; i < recent.length; i++) {
            if (recent[i].name === org.name) {
                recent.splice(i, 1)
                break
            }
        }

        // remove the last if it's greater than the max
        if (recent.length >= UI_CONFIG.MAX_RECENT_ORGS) {
            recent.pop()
        }

        // add the new recent to the front
        recent.unshift(org)

        context.commit('RecentOrganizationsUpdated', recent)
    },

    AddUsersToSubscription ({getters, commit}, {organizationId, subscriptionId, users, tierType}) {
        const internalIdentifier = getters.getInternalIdentifier(subscriptionId, tierType)
        return new Promise((resolve, reject) => {
            createApiClient().setUsersForSubscription(organizationId, subscriptionId, users, internalIdentifier)
                .then(({succeeded, failed}) => {
                    const sanitizedUsers = succeeded.map(user => sanitizeUserData(user, tierType))
                    commit('UsersAdded', {subscriptionId, users: sanitizedUsers, tierType})
                    resolve(failed)
                }).catch(reject)
        })
    },

    setUserDistrictLibraryRolesInSubscription ({getters, commit}, {organizationId, subscriptionId, users, tierType}) {
        const internalIdentifier = getters.getInternalIdentifier(subscriptionId, tierType)
        return new Promise((resolve, reject) => {
            createApiClient().setUsersForSubscription(organizationId, subscriptionId, users, internalIdentifier)
                .then(({succeeded, failed}) => {
                    // When receiving the users, they must be sanitized before being added/updated in the store
                    const sanitizedUsers = succeeded.map(user => sanitizeUserData(user, tierType))
                    commit('updateDistrictLibraryUserRolesInSubscription', { users: sanitizedUsers })
                    resolve(failed)
                }).catch(reject)
        })
    },

    RemoveUsersFromSubscription ({getters, commit}, {organizationId, subscriptionId, users, tierType}) {
        const internalIdentifier = getters.getInternalIdentifier(subscriptionId, tierType)
        return new Promise((resolve, reject) => {
            createApiClient().removeUsersFromSubscription(organizationId, subscriptionId, users, internalIdentifier)
                .then(ignored => {
                    commit('UsersRemoved', {subscriptionId, users, tierType})
                    resolve()
                }).catch(reject)
        })
    },

    FetchUsersForSubscription ({getters, commit}, {organizationId, subscriptionId, tierType, token}) {
        const internalIdentifier = getters.getInternalIdentifier(subscriptionId, tierType)
        return new Promise((resolve, reject) => {
            let LoadingFlag = true
            let canceled = false
            let clearSubscriptionsOnCancel = false
            commit('setLoadingFlag', LoadingFlag)

            token.cancel = function (removeUsers) {
                canceled = true
                clearSubscriptionsOnCancel = removeUsers
            }

            let getAllUsersBatches = (onResolve, onReject, nextToken) => {
                return createApiClient().getUsersForSubscription(organizationId, subscriptionId, nextToken, internalIdentifier)
                    .then((result) => {
                        if (canceled) {
                            LoadingFlag = false
                            if (clearSubscriptionsOnCancel) {
                                commit('RemoveAllSubscriptionsUserList')
                            }
                            commit('setLoadingFlag', LoadingFlag)
                            onResolve()
                        } else {
                            const sanitizedUsers = result.json.map(user => sanitizeUserData(user, tierType))
                            commit('UsersAdded', {subscriptionId, users: sanitizedUsers})
                            if (result.nextToken) {
                                onResolve(
                                    new Promise((resolve, reject) => {
                                        return getAllUsersBatches(resolve, reject, result.nextToken).catch(err => reject(err))
                                    })
                                )
                            } else {
                                commit('UpdateSubscriptionUsage', { subscriptionId, tierType })
                                LoadingFlag = false
                                commit('setLoadingFlag', LoadingFlag)
                                onResolve()
                            }
                        }
                    }).catch(err => {
                        onReject(err)
                    })
            }
            return getAllUsersBatches(resolve, reject).catch(err => reject(err))
        })
    },

    SyncTeacherToSubscription ({getters}, {organizationId, subscriptionId, sisOrganizationId, source, tierType}) {
        const internalIdentifier = getters.getInternalIdentifier(subscriptionId, tierType)
        return new Promise((resolve, reject) => {
            createApiClient().syncTeachers(organizationId, subscriptionId, sisOrganizationId, internalIdentifier, source)
                .then(result => {
                    resolve()
                }).catch(reject)
        })
    },

    initiateClientConnection ({commit, getters}, {slsSubId, subscriptionId, orgId, groupKey, windowRef, source, tierType}) {
        let clearClientConnectionReferences = function (intervalRef, windowCloseRef) {
            commit('setClientConnectionWindowReference', null)
            clearInterval(intervalRef)
            clearInterval(windowCloseRef)
            commit('setClientConnectionPullingIntervalReference', null)
            window.localStorage.removeItem(code)
        }
        let connection = getters.getClientConnection
        if (connection.windowReference !== null) {
            connection.windowReference.close()
            clearInterval(connection.pullingIntervalReference)
        }
        let syncWindowClosable = true
        let code = ''
        // Login is done in another App so we need to send the version of the /connect API We are using
        // since we do not have FS information there sending the API version through the URL param
        // This condition may change in the future if we need to handle more API versions
        const apiVersion = isEnabled(FUSE_API) ? '3' : null
        const internalIdentifier = isEnabled(FUSE_API) ? getters.getInternalIdentifier(subscriptionId, tierType) : slsSubId

        return new Promise((resolve, reject) => {
            commit('setClientConnectionWindowReference', windowRef)
            createApiClient().initiateClientConnection(internalIdentifier, orgId, groupKey, source)
                .then(result => {
                    code = result.json.code
                    let endpoint = '/connect/idp/index.html'
                    endpoint += '?code=' + code
                    endpoint += '&source=' + source
                    endpoint += '&reg=' + environ.getRegion()
                    if (apiVersion) {
                        endpoint += '&apiV=' + apiVersion
                    }
                    windowRef.location.href = endpoint
                    let intervalRef = setInterval(() => {
                        createApiClient().getConnectionStatus(code).then(status => {
                            if (status.json.status === 'COMPLETED') {
                                if (syncWindowClosable) {
                                    windowRef.close()
                                    clearClientConnectionReferences(intervalRef, windowCloseRef)
                                    resolve()
                                }
                            } else if (status.json.status !== 'SIGNED_IN' && status.json.status !== 'STARTED') {
                                let error = {
                                    traceId: status.json.traceId,
                                    errorCode: INTERNAL_ERROR
                                }
                                if (syncWindowClosable) {
                                    windowRef.close()
                                    clearClientConnectionReferences(intervalRef, windowCloseRef)
                                    reject(error)
                                }
                            }
                        }).catch(err => {
                            if (syncWindowClosable) {
                                windowRef.close()
                                clearClientConnectionReferences(intervalRef, windowCloseRef)
                                reject(err)
                            }
                        })
                    }, 10000)
                    let windowCloseRef = setInterval(() => {
                        syncWindowClosable = (window.localStorage.getItem(code) === null)
                        if (windowRef.closed) {
                            createApiClient().getConnectionStatus(code).then(status => {
                                let error = {
                                    closed: true,
                                    status: status.json.status,
                                    traceId: status.json.traceId
                                }
                                if (error.status === 'SIGNED_IN' || error.status === 'STARTED') {
                                    error.grantCode = result.json.code
                                }
                                clearClientConnectionReferences(intervalRef, windowCloseRef)
                                reject(error)
                            }).catch(err => {
                                clearClientConnectionReferences(intervalRef, windowCloseRef)
                                reject(err)
                            })
                        }
                    }, 500)
                    commit('setClientConnectionPullingIntervalReference', intervalRef)
                })
                .catch(err => {
                    if (syncWindowClosable) {
                        windowRef.close()
                        commit('setClientConnectionWindowReference', null)
                        reject(err)
                    }
                })
        })
    },

    getConnectionStatus ({commit}, code) {
        return new Promise((resolve, reject) => {
            let intervalRef = setInterval(() => {
                createApiClient().getConnectionStatus(code).then(status => {
                    if (status.json.status === 'COMPLETED') {
                        clearInterval(intervalRef)
                        resolve()
                    } else if (status.json.status !== 'SIGNED_IN' && status.json.status !== 'STARTED') {
                        let error = {
                            traceId: status.json.traceId,
                            errorCode: INTERNAL_ERROR
                        }
                        clearInterval(intervalRef)
                        reject(error)
                    }
                }).catch(err => {
                    clearInterval(intervalRef)
                    reject(err)
                })
            }, 10000)
        })
    },

    revokeConnectionGrant ({commit}, code) {
        return new Promise((resolve, reject) => {
            createApiClient().revokeConnectionGrant(code).then(() => {
                resolve()
            }).catch(err => {
                reject(err)
            })
        })
    },

    FetchSLSSubscription ({commit}, {organizationId, subscriptionId, tierType}) {
        return new Promise((resolve, reject) => {
            createApiClient().getSubscription(organizationId, subscriptionId).then(result => {
                const internalIdentifier = result.json.subscriptionId
                const internalOrganizationId = result.json.organizationId
                let source, tiers
                // TODO: Redo below logic when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
                if (isEnabled(FUSE_API)) {
                    tiers = result.json.tiers
                    source = tiers[tierType].source
                } else {
                    source = result.json.source
                }
                commit('UpdateSubscription', {
                    subscriptionId,
                    internalSubscriptionId: internalIdentifier,
                    internalOrganizationId,
                    source,
                    tierType,
                    tiers
                })
                resolve(result.json)
            }).catch(err => {
                reject(err)
            })
        })
    },

    UpdateSource ({commit, getters}, {organizationId, subscriptionId, source, internalSubscriptionId, tierType, tierId}) {
        const internalIdentifier = getters.getInternalIdentifier(subscriptionId, tierType)
        return new Promise((resolve, reject) => {
            createApiClient().updateSource(organizationId, subscriptionId, internalIdentifier, source, internalSubscriptionId, tierType, tierId).then(result => {
                commit('UpdateSubscription', { subscriptionId, internalSubscriptionId, source, tierType })
                resolve(result)
            }).catch(err => {
                reject(err)
            })
        })
    },

    DisconnectAutoProvision ({getters}, {organizationId, subscriptionId, tierType}) {
        const internalIdentifier = getters.getInternalIdentifier(subscriptionId, tierType)
        return new Promise((resolve, reject) => {
            createApiClient().disconnectAutoProvision(organizationId, subscriptionId, internalIdentifier).then((result) => {
                resolve(result)
            }).catch(err => {
                reject(err)
            })
        })
    },

    UpdatePageTitle ({commit}, pageTitle) {
        commit('UpdatePageTitle', pageTitle)
    },
    // TODO: remove below function when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
    FetchSubscriptionsForOrganization (context, {organizationId, respListener, expireListener}) {
        LicenseRequestService.registerResponseListener(respListener)
        LicenseRequestService.registerDataExpiredListener(expireListener)

        let numOrgsToProcess = 0
        if (!context.state.ui.selectedOrganizationId && !organizationId.length) {
            numOrgsToProcess = LicenseRequestService.requestLicenses(context.getters.getOrganizations.map(org => org.id))
        } else if (Array.isArray(organizationId) && organizationId.length) {
            numOrgsToProcess = LicenseRequestService.requestLicenses(organizationId)
        } else {
            numOrgsToProcess = LicenseRequestService.requestLicenses([organizationId])
        }

        return numOrgsToProcess
    },
    FetchSubscriptionsForOrganizationHybrid (context, {organizationId, respListener, expireListener}) {
        LicenseRequestService.registerResponseListener(respListener)
        LicenseRequestService.registerDataExpiredListener(expireListener)

        let organizationsIds
        if (!context.state.ui.selectedOrganizationId && !organizationId.length) {
            organizationsIds = context.getters.getOrganizations.map(org => org.id)
        } else if (Array.isArray(organizationId) && organizationId.length) {
            organizationsIds = organizationId
        } else {
            organizationsIds = [organizationId]
        }
        const allOrgsLoaded = LicenseRequestService.requestLicensesHybrid(organizationsIds)
        return allOrgsLoaded
    },
    GetUpdatedOrganizationSubscriptions (context, { organizationId, respListener }) {
        LicenseRequestService.registerResponseListener(respListener)
        LicenseRequestService.requestUpdatedOrgSubscriptions([organizationId])
    },
    ClearSubscriptionsUserList ({commit}) {
        commit('RemoveAllSubscriptionsUserList')
    },
    ValidateCodeOrHardwareClaim (context) {
        if (!context.state.activation.redeem.codeValidated || context.state.activation.claim.updated) {
            return new Promise((resolve, reject) => {
                return createApiClient().validateClaimCodeOrSerialNumberClaim(
                    context.state.activation.redeem.code,
                    context.state.activation.claim.serialNumber,
                    context.state.activation.claim.purchasedBoardCount)
                    .then((result) => {
                        context.commit('setActivationRedeemCodeValidated', true)
                        context.commit('setActivationClaimUpdated', false)
                        context.commit('setProductList', result.json)
                        resolve(result)
                    }).catch(error => {
                        reject(error)
                    })
            })
        }
    },
    claimEntitlement (context) {
        // Skip the activation if we are just debugging claim flow screens
        if (isEnabled(DEBUG_STOCKING_CLAIM_FLOW) || isEnabled(DEBUG_NON_STOCKING_CLAIM_FLOW)) {
            return Promise.resolve()
        }

        return new Promise((resolve, reject) => {
            // create payload
            let activation = context.state.activation
            let payload = {
                entitlement: {}
            }
            let error = {errorCode: BAD_REQUEST_ERROR}

            // validate claim code and hardware information.
            if (entitlement.validateClaimCode(activation.redeem.code)) {
                payload.entitlement.claimCode = activation.redeem.code
            } else if (entitlement.validateClaimHardware(activation.claim.serialNumber, activation.claim.purchasedBoardCount)) {
                payload.entitlement.serialNumber = activation.claim.serialNumber
                payload.entitlement.eligibleUnitsPurchased = activation.claim.purchasedBoardCount
            } else {
                error.message = 'must have claim code or hardware SN and purchased board count, not both.'
                throw error
            }
            let selectedOrganizationStatus = strUtils.isNotBlank(activation.selectedOrganizationId)
            let createdOrganizationStatus = entitlement.validateCreatedOrganization(activation.userAccount)

            // validating either selected organization or created one.
            if (selectedOrganizationStatus ? createdOrganizationStatus : !createdOrganizationStatus) {
                error.message = 'must have either selectedOrganization but not both'
                throw error
            } else if (selectedOrganizationStatus) {
                payload.portalGroupId = activation.selectedOrganizationId
            } else {
                payload.userAccount = activation.userAccount
                if (payload.userAccount.phone?.length === 0) {
                    payload.userAccount.phone = ''
                }
            }

            // validating reseller city and name. must have both
            if (reseller.validateReseller(activation.reseller)) {
                payload.reseller = activation.reseller
            }

            // call api
            return createApiClient().claimEntitlement(cleanObject(payload), activation.redeem.code, activation.claim.serialNumber)
                .then((result) => {
                    let res = result.json
                    context.commit('InsertDirectOrganization', [res])
                    if (createdOrganizationStatus) {
                        context.commit('setCreatedOrganizationId', res.id)
                    }

                    if (res.subscription != null) {
                        context.commit('SubscriptionReceived', {
                            organizationId: res.subscription.accountId,
                            organizationName: res.subscription.accountName,
                            subscription: res.subscription
                        })
                    }
                    if (res.legacyAssets !== null && res.legacyAssets !== undefined) {
                        let legacyInfo = {
                            orgId: res.organization.id,
                            data: res.legacyAssets,
                            isLegacy: true,
                            error: false
                        }
                        SubscriptionUpdater.updateSubscription(legacyInfo, context)
                    }
                    this.dispatch('SelectedOrganizationUpdated', {
                        name: res.name,
                        id: res.id
                    })

                    resolve()
                }).catch((error) => {
                    reject(error)
                })
        })
    },
    ValidateClaimCode ({commit}, {claimCode}) {
        return new Promise((resolve, reject) => {
            return createApiClient().validateClaimCodeOrSerialNumberClaim(
                claimCode)
                .then((result) => {
                    commit('setSubscriptionSelectedMenuOption', MENU_STATE.ACTIVATION)
                    commit('setActivationRedeemCode', claimCode)
                    commit('setProductList', result.json)
                    resolve(result)
                }).catch(error => {
                    reject(error)
                })
        })
    },
    FetchAdminsForUser ({commit}) {
        return new Promise((resolve, reject) => {
            let getAdmins = (onResolve, onReject, nextToken) => {
                return createApiClient().getAdminsForUserOrganizations(nextToken).then(result => {
                    commit('adminsForOrganizationsReceived', result.json)
                    if (result.nextToken) {
                        onResolve(new Promise((resolve, reject) => {
                            return getAdmins(resolve, reject, result.nextToken).catch(err => reject(err))
                        }))
                    } else {
                        onResolve()
                    }
                }).catch(err => {
                    onReject(err)
                })
            }
            return getAdmins(resolve, reject).catch(err => reject(err))
        })
    },
    ClearRoleChangeNotification ({commit}, orgIds) {
        return new Promise((resolve, reject) => {
            return createApiClient().clearChangeNotification(orgIds).then(() => {
                commit('clearNotifications', orgIds.declined)
                resolve()
            }).catch(err => {
                reject(err)
            })
        })
    },
    AddRemoveEditUsersFromOrganization ({commit}, roleChangeRequest) {
        return new Promise((resolve, reject) => {
            return createApiClient().roleChangeRequestForOrganization(roleChangeRequest).then((result) => {
                let removedAdmins = roleChangeRequest.filter(request => request.requestType === REQUEST_TYPE.REMOVE)
                if (result.json.length) {
                    commit('updateAdminsInAdminTable', result.json)
                }
                if (removedAdmins.length) {
                    commit('removeAdminsFromAdminTable', removedAdmins)
                }
                resolve()
            }).catch(err => {
                if (err.code === ErrorCodes.DELETE_LAST_ADMIN_ERROR) {
                    let parsedErrMessage = err.message.split(',')
                    let organization = parsedErrMessage.length === 1 ? parsedErrMessage[0]
                        : TranslationService.tv('management.adminDialog.numOrgs', {quantity: parsedErrMessage.length})
                    err.message = TranslationService.tv('management.removeAdmin.onlyAdminWarning', {organization: organization})
                }
                reject(err)
            })
        })
    },
    FetchRolesForOrganization ({commit}) {
        // TODO: Add orgId as parameter later when custom roles are implemented
        return new Promise((resolve, reject) => {
            return createApiClient().getRolesForOrganization().then((result) => {
                resolve(result.json)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    UpdateOrgInfo (context, toBeUpdatedOrg) {
        // single org update for organization update dialog
        let payload = []
        payload.push(toBeUpdatedOrg)

        return new Promise((resolve, reject) => {
            return createApiClient().updateOrganizations(payload)
            .then(result => {
                let res = result.json

                if (res.length === 0) {
                    let errorResponse = {
                        errorCode: ErrorCodes.ORGANIZATION_NOT_FOUND
                    }
                    reject(errorResponse)
                }

                context.commit('updateDirectOrgs', res)

                if (strUtils.isNotBlank(toBeUpdatedOrg.name)) {
                    context.commit('updateDirectOrgsName', res)
                }

                resolve()
            }).catch((err) => {
                reject(err)
            })
        })
    },
    UpdateOrganizationRegion (context, toBeUpdatedOrgs) {
        const MAX_ORGS_PER_REQUEST = 20
        let promises = []

        while (toBeUpdatedOrgs.length > 0) {
            let chunkedOrgsArray = toBeUpdatedOrgs.splice(0, MAX_ORGS_PER_REQUEST)

            let promise = createApiClient().updateOrganizations(chunkedOrgsArray)
                .then(result => {
                    let res = result.json
                    context.commit('updateDirectOrgs', res)
                })

            promises.push(promise)
        }

        return Promise.all(promises)
    },

    loadDLMetadata ({commit}, {orgId, extOrgId, subId, extSubId}) {
        return new Promise((resolve, reject) => {
            return createApiClient().loadDLMetadata({orgId, extOrgId, subId, extSubId})
            .then(result => {
                commit('cacheDistrictLibrary', {...result.json[0], uploadedBy: extOrgId})
                resolve()
            }).catch(err => {
                reject(err)
            })
        })
    },

    updateAllowUploadForDL ({ commit }, { districtLibraryId, extOrgId, subId, extSubId, val }) {
        return new Promise((resolve, reject) => {
            return createApiClient().updateAllowUploadForDL({ districtLibraryId, extOrgId, subId, extSubId, val })
            .then(() => {
                commit('updateDistrictLibrary', { districtId: districtLibraryId, allowUpload: val })
                resolve()
            }).catch(err => {
                reject(err)
            })
        })
    },

    generateLicenseKey ({ commit }, { organizationId, subscriptionId }) {
        return new Promise((resolve, reject) => {
            return createApiClient().generateLicenseKey({ organizationId, subscriptionId })
            .then(() => {
                commit('setLicenseKeyRequested', subscriptionId)
                resolve()
            }).catch(err => {
                reject(err)
            })
        })
    },

    getOrgSettings ({ commit }, { orgId, extOrgId }) {
        return new Promise((resolve, reject) => {
            return createApiClient().getOrgSettings(orgId, extOrgId)
            .then(result => {
                resolve(result.json)
            }).catch(err => {
                reject(err)
            })
        })
    },

    updateOrgSettings ({ commit }, { orgId, extOrgId, settings }) {
        return new Promise((resolve, reject) => {
            return createApiClient().updateOrgSettings(orgId, extOrgId, settings)
            .then(result => {
                resolve(result.json)
            }).catch(err => {
                reject(err)
            })
        })
    }
}
