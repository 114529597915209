<template>
    <div :class="['toggle-section', 'd-flex', 'align-center', 'my-4', { disabled: disabled }]">
        <div class="text mr-2"> {{ message }} </div>
        <v-icon v-if="disabled" class="info-icon d-flex justify-center align-center"></v-icon>
        <item-with-tooltip v-else :tooltip-text="tooltipText">
            <template v-slot:item>
                <v-icon class="info-icon d-flex justify-center align-center"></v-icon>
            </template>
        </item-with-tooltip>
        <div class="flex-grow-1 d-flex justify-end">
            <v-switch v-model="toggleValue"
                class="toggle-switch mt-0"
                hide-details="auto"
                inset
                :color="switchColor"
                :disabled="disabled">
            </v-switch>
        </div>
    </div>
</template>

<script>
import ItemWithTooltip from './ItemWithTooltip.vue'

export default {
    name: 'ToggleSection',
    components: {
        ItemWithTooltip
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: ''
        },
        tooltipText: {
            type: String,
            default: ''
        }
    },
    computed: {
        switchColor() {
            return this.value ? '#159BD9' : '#D5D5D5'
        },
        toggleValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('toggle-updated', value)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../theming/main.scss";

.toggle-section {
    &.disabled {
        .text {
            color: $theme-disabled;
        }
    }

    &:not(.disabled) {
        .info-icon:hover::before {
            content: "info";
        }
    }
}

.text {
    font-size: 16px;
}

.info-icon::before {
    font-size: 20px;
    content: "info_outline";
}

.toggle-switch {
    margin-right: -18px !important;
}

</style>
