<template>
    <v-dialog v-model="openDialog" width="590px" persistent content-class="add-users-settings-modal">
        <v-card class="pa-0 d-flex flex-column overflow-y-hidden">
            <div class="org-library-settings px-10">
                <div class="title"> {{ $t('addUsersSettingsModal.orgSettings') }} </div>
                <div class="message mt-3">
                    {{ getDescription }}
                </div>
                <div class="title"> {{ $t('addUsersSettingsModal.orgLibrarySettings') }} </div>
                <hr>
                <toggle-section
                    :value="toggleAllowUpload"
                    :message="$t('management.orgSettings.toggleMessage')"
                    :tooltip-text="$t('management.orgSettings.tooltipText')"
                    @toggle-updated="onAllowUploadModified">
                </toggle-section>
                <v-btn id="addUsersSettingsModalConfirmButton"
                   block primary light
                   class="white--text mt-9 confirm-button"
                   height="40px"
                   @click="triggerRequest">
                {{ $t('buttons.confirm') }}
            </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    import ToggleSection from '../ToggleSection.vue'
    import { mapGetters, mapActions } from 'vuex'
    import ErrorHandler from '../../utils/error-handler'

    export default {
        name: 'UserSettingsModal',
        components: {
            ToggleSection
        },
        data () {
            return {
                openDialog: false,
                originalAllowUpload: false,
                toggleAllowUpload: false
            }
        },
        props: {
            subscriptionId: {
                type: String,
                default: ''
            },
            rosterSource: {
                type: Boolean,
                default: false
            },
            provider: {
                type: String,
                default: ''
            }
        },
        async created () {
            const { organizationId, id, internalOrganizationId, internalSubscriptionId } = this.getSubscription
            await this.loadDLMetadata({extOrgId: organizationId, extSubId: id, orgId: internalOrganizationId, subId: internalSubscriptionId}).then(() => {
                const { allowUpload } = this.getDistrictLibraryByOrg(organizationId)
                this.originalAllowUpload = allowUpload
                this.toggleAllowUpload = allowUpload
                this.openDialog = true
            }).catch((error) => {
                this.handleError(error, ErrorHandler.dialogueError)
            })
        },
        watch: {
            'openDialog' (to) {
                this.$store.commit('setIsModalOpen', to)
            }
        },
        computed: {
            ...mapGetters([
                'getSubscriptionById',
                'getDistrictLibraryByOrg'
            ]),
            getSubscription () {
                return this.getSubscriptionById(this.subscriptionId)
            },
            isAllowUploadUpdated() {
                return this.originalAllowUpload !== this.toggleAllowUpload
            },
            getDescription() {
                const organizationName = this.getSubscription.accountName
                return this.rosterSource ? this.$t('addUsersSettingsModal.descriptionAuto', { provider: this.provider }) : this.$t('addUsersSettingsModal.descriptionManual', { organizationName })
            }
        },
        methods: {
            ...mapActions([
                'loadDLMetadata',
                'updateAllowUploadForDL'
            ]),
            onAllowUploadModified(value) {
                this.toggleAllowUpload = value
            },
            handleError(error) {
                ErrorHandler.handleError(error, ErrorHandler.orgUpdateError)
                this.closeDialog()
            },
            closeDialog() {
                this.openDialog = false
                this.$store.commit('setIsModalOpen', false)
                this.$emit('close-dialog')
            },
            triggerRequest () {
                if (this.isAllowUploadUpdated) {
                    try {
                        const { organizationId, id, internalSubscriptionId } = this.getSubscription
                        const { districtId } = this.getDistrictLibraryByOrg(organizationId)
                        this.updateAllowUploadForDL({
                            extOrgId: organizationId,
                            districtLibraryId: districtId,
                            subId: internalSubscriptionId,
                            extSubId: id,
                            val: this.toggleAllowUpload
                        })
                    } catch (error) {
                        this.handleError(error)
                    }
                }

                this.closeDialog()
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../../theming/main.scss";

.org-library-settings {
    margin-bottom: 50px;
    div {
        color: $pallete-dark-gray;
    }
}

.title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 13px;
}

hr {
    border: none;
    height: 1px;
    background-color: $pallete-offwhite;
}

.message {
    font-size: 16px;
}

.confirm-button {
    font-size: 18px !important;
}
</style>
