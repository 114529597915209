import StrUtils from 'smarttech-identity-string-utils'
import { DEBUG_NON_STOCKING_CLAIM_FLOW, DEBUG_STOCKING_CLAIM_FLOW, FUSE_API } from '../enums/feature-switch-constants.js'
import { isEnabled } from '../services/feature-switch.js'
import { fakeAdminsForAllOrgsGetter } from '../utils/fake-claim-code-data'
import { DSL_ROLES } from '../enums/dsl-constants'

export default {
    accessToken (state) {
        return state.accessToken
    },
    hasAccessToken (state) {
        return !!state.accessToken
    },
    appState (state) {
        return state.appState
    },
    dialogState (state) {
        return state.dialogState
    },
    error (state) {
        return state.user.error
    },
    userProfile (state) {
        return state.user.profile
    },
    users (state) {
        return state.subscriptionUsers.users
    },
    organizationDistrictLibraryHasAdmin (state) {
        const orgUsers = state.subscriptionUsers.users
        return orgUsers.some(user => user.dlRoles.includes(DSL_ROLES.ADMIN))
    },
    pageTitle (state) {
        return state.ui.pageTitle
    },
    getInternalSubscriptionId (state) {
        return subscriptionId => {
            let subscription = state.subscriptions[subscriptionId]
            if (subscription) {
                return subscription.internalSubscriptionId
            }
            return null
        }
    },
    getSubscriptionSource (state) {
        return subscriptionId => {
            let subscription = state.subscriptions[subscriptionId]
            if (subscription) {
                return subscription.source
            }
            return null
        }
    },
    getInternalTierId (state, getters) {
        return (subscriptionId, tierType) => {
            let subscription = state.subscriptions[subscriptionId]
            if (subscription) {
                return getters.isMsdSubscription(subscriptionId) ? subscription.tiers[tierType].tierId : subscription.tierId
            }
            return null
        }
    },
    getTierType (state) {
        return (subscriptionId, extTierId) => {
            let subscription = state.subscriptions[subscriptionId]
            if (subscription && extTierId) {
                return Object.keys(subscription.tiers).find(key => subscription.tiers[key].id === extTierId)
            }
            return null
        }
    },
    getInternalIdentifier (state, getters) {
        return (subscriptionId, tierType) => {
            let subscription = state.subscriptions[subscriptionId]
            if (!subscription) {
                return null
            }
            // TODO: remove below logic when cleaning up v2 API in story https://smartvcs.visualstudio.com/SLSO/_workitems/edit/200569
            if (!isEnabled(FUSE_API)) {
                return subscription.internalSubscriptionId
            }
            const tierId = getters.getInternalTierId(subscriptionId, tierType)
            return `TIER:${subscription.internalSubscriptionId}:${tierId}`
        }
    },
    selectedOrganizationId (state) {
        return state.ui.selectedOrganizationId
    },
    selectedOrganizationName (state) {
        return state.ui.selectedOrganizationName
    },
    selectedOrganizationSubscriptions(state, getters) {
        return getters.subscriptionsByOrganization(state.ui.selectedOrganizationId)
    },
    subscriptionsByOrganization (state) {
        return organizationId => {
            const subscriptions = []
            Object.values(state.subscriptions).forEach(subscription => {
                if (subscription.organizationId === organizationId) {
                    subscriptions.push(subscription)
                }
            })
            return subscriptions
        }
    },
    getOrganizationById (state, getters) {
        return orgId => getters.getOrganizations.find(x => x.id === orgId)
    },
    getDistrictLibraryById (state) {
        return districtId => Object.keys(state.districtLibraries).find(x => x === districtId)
    },
    getDistrictLibraryByOrg (state) {
        return orgId => Object.values(state.districtLibraries).find(x => x.uploadedBy === orgId)
    },
    getSubscriptionById (state) {
        return subscriptionId => {
            return state.subscriptions[subscriptionId] || null
        }
    },
    getTierById (state) {
        return (subscriptionId, tierType) => {
            return state.subscriptions[subscriptionId].tiers[tierType]
        }
    },
    isMsdSubscription (state) {
        return subscriptionId => {
            return state.subscriptions[subscriptionId]?.isMsdSubscription || false
        }
    },
    getAllSubscriptions (state) {
        return Object.values(state.subscriptions)
    },
    orgHasSubscriptionsLoaded(state, getters) {
        return orgId => getters.getOrganizationById(orgId).subscriptionsLoaded
    },
    orgHasSLSSubscription(state, getters) {
        // Check if the org already has an existing SLS subscription.
        // - All subscriptions provided by the subscriptions endpoint are SLS subscriptions
        // - All subscriptions provided by the legacy subscriptions endpoint are not SLS subscriptions
        // Therefore, we can just check if the organization has any subscription where isLegacy is false.
        return orgId => getters.subscriptionsByOrganization(orgId).some(subscription => !subscription.isLegacy)
    },
    orgMsdSubscription (state, getters) {
        return orgId => {
            const subscriptions = getters.subscriptionsByOrganization(orgId)
            return subscriptions.find(subscription => !!subscription.tiers)
        }
    },
    orgSparkSubscription (state, getters) {
        return orgId => {
            const subscriptions = getters.subscriptionsByOrganization(orgId)
            return subscriptions.find(subscription => !!subscription.tiers?.SPARK)
        }
    },
    orgStandardSubscription (state, getters) {
        return orgId => {
            const subscriptions = getters.subscriptionsByOrganization(orgId)
            return subscriptions.find(subscription => !!subscription.tiers?.BASE)
        }
    },
    getSparkSubscriptions(state, getters) {
        return getters.getAllSubscriptions.filter(subscription => !!subscription.tiers?.SPARK)
    },
    showOutageAlert (state) {
        return state.outageStatus.showAlert
    },
    outageType (state) {
        return state.outageStatus.outageType
    },
    getOrganizations (state) {
        return state.organizations.direct.concat(state.organizations.related)
    },
    getDirectOrganizations (state) {
        return state.organizations.direct
    },
    getSubscriptionSelectedMenuOption (state) {
        return state.subscriptionMenu.selectedMenuOption
    },
    getUserAccount (state) {
        return state.activation.userAccount
    },
    getRelatedOrganizationMap (state) {
        return state.organizations.tracking.relatedMap
    },
    getClientConnection (state) {
        return state.clientConnection
    },
    getLoadingStatus (state) {
        return state.subscriptionUsers.loadingFlag
    },
    getManagementSelectedMenuOption (state) {
        return state.managementMenu.selectedMenuOption
    },
    getOrganizationsForManagement (state) {
        return StrUtils.isNotBlank(state.organizations.filterByName) ? state.organizations.filtered : state.organizations.direct
    },
    getOrganizationsForValidation (state) {
        return state.organizations.permissionsByOrg
    },
    getCurrentSearchOrgByNameValue (state) {
        return state.organizations.filterByName
    },
    areAdminsLoadedForUser (state) {
        return Object.keys(state.organizationAdmins.adminsForOrg).length !== 0
    },
    getCurrentOrganizationInAdminTable (state) {
        return state.organizationAdmins.orgId
    },
    getAllLoadedAdmins (state) {
        return state.organizationAdmins.allAdmins
    },
    getAdminsForCurrentOrganization (state) {
        return StrUtils.isNotBlank(state.organizationAdmins.filterByName) || StrUtils.isNotBlank(state.organizationAdmins.filterByRole)
            ? state.organizationAdmins.filtered : Object.values(state.organizationAdmins.adminsForOrg)
    },
    getAdminsOfAllOrgs (state) {
        // Get a fake list of admins when debugging claim flows
        if (isEnabled(DEBUG_STOCKING_CLAIM_FLOW) || isEnabled(DEBUG_NON_STOCKING_CLAIM_FLOW)) {
            return fakeAdminsForAllOrgsGetter
        }
        return Object.values(state.organizationAdmins.allAdmins).filter(admin => {
            let organizations = Object.values(admin.organizations)
            if (admin.organizations[state.activation.userAccount.id]) {
                return false
            } else if (organizations.length < state.organizations.direct.length - 1) {
                return false
            } else {
                return true
            }
        })
    },
    getSelectedAdminsInAdminTable (state) {
        return state.organizationAdmins.selected
    },
    getAdminCountForOrgs (state) {
        return state.organizationAdmins.adminCountForOrg
    },
    getCurrentSearchByRoleValue (state) {
        return state.organizationAdmins.filterByRole
    },
    getCurrentSearchByNameValue (state) {
        return state.organizationAdmins.filterByName
    },
    getCurrentNotifications (state) {
        return state.notifications
    },
    getCreatedOrganizationId (state) {
        return state.activation.userAccount.id
    },
    getDirectOrganizationsCount (state) {
        return state.organizations.direct.length
    },
    getExistingActivationAdminState (state) {
        return state.activation.admins
    },
    isActivationAdminListLoaded (state) {
        return state.activation.admins.previouslyLoaded
    },
    getManageUsersOnboardingStep (state) {
        return state.userSettings.manageUsersOnboardingStep
    },
    getOnboardingTotalSteps (state) {
        return state.onboarding.totalSteps
    },
    shouldTriggerOnboarding (state) {
        return state.userSettings.manageUsersOnboardingStep !== 0 && !state.onboarding.hideOnboarding
    },
    getIsModalOpen (state) {
        return state.isModalOpen
    },
    getSubscriptionExpiryConfirmedDate (state) {
        return state.userSettings.subscriptionExpiryConfirmedDate
    },
    getIsOnboardingActiveFlow(state) {
        return state.onboarding.isActiveFlow
    }
}
