<template>
    <div class="org-library-settings px-12">
        <div v-if="showOnlySparkMessage" class="message mt-5 mb-5">{{ $t('management.orgSettings.onlySpark') }}</div>
        <div v-if="showAISetting && !isSparkOrganization" class="message mt-5 mb-5">
            {{$t('management.orgSettings.newDescription') }}
            <a class="font--bold text-decoration-none" :href="$LINKS.PRICING " target="_blank"> {{ $t('management.orgSettings.learnMore') }}</a>
        </div>
        <div class="title mt-4 mb-2"> {{ title }} </div>
        <hr>
        <!--TODO Remove the below div when the show ai flag is removed-->
        <div v-if="!showAISetting && !isSparkOrganization" class="message mt-3">
            {{ this.$t('management.orgSettings.description') }}
            <a class="font--bold text-decoration-none" :href="$LINKS.LUMIO" target="_blank"> {{ $t('management.orgSettings.learnMore') }}</a>.
        </div>
        <toggle-section
            :value="allowUpload"
            :disabled="!isSparkOrganization"
            :message="$t('management.orgSettings.toggleMessage')"
            :tooltip-text="$t('management.orgSettings.tooltipText')"
            @toggle-updated="onAllowUploadModified">
        </toggle-section>
        <div :class="['message', 'my-2', { disabled: !isSparkOrganization }]">
            {{ $t('management.orgSettings.bottomMessage') }}
            <a v-if="isSparkOrganization" class="font--bold text-decoration-none" :href="getLibraryLink" target="_blank"> {{ $t('management.orgSettings.lumio') }}</a>
            <span v-else class="font--bold"> {{ $t('management.orgSettings.lumio') }}</span>.
        </div>
        <template v-if="showAISetting">
            <div class="title mt-4 mb-2"> {{ $t('management.generativeAI.title') }} </div>
            <hr>
            <toggle-section
                :value="allowGenerativeAI"
                :disabled="!isSparkOrganization"
                :message="$t('management.generativeAI.toggleMessage')"
                :tooltip-text="$t('management.generativeAI.tooltipText')"
                @toggle-updated="onAllowGenerativeAIModified">
            </toggle-section>
        </template>
    </div>
</template>

<script>
import ToggleSection from '../ToggleSection.vue'
import { isEnabled } from '../../services/feature-switch'
import { SHOW_AI_ORG_SETTING } from '../../enums/feature-switch-constants'

export default {
    name: 'OrgLibrarySettings',
    components: {
        ToggleSection
    },
    props: {
        allowUpload: {
            type: Boolean,
            default: false
        },
        allowGenerativeAI: {
            type: Boolean,
            default: false
        },
        isSparkOrganization: {
            type: Boolean,
            default: false
        },
        libraryId: {
            type: String,
            default: ''
        },
        hasStandardSubscription: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        getLibraryLink() {
            return `${this.$LINKS.LUMIO_ORG_LIBRARY_CONTENT}/${this.libraryId}`
        },
        showAISetting() {
            return isEnabled(SHOW_AI_ORG_SETTING)
        },
        title() {
            return this.showAISetting ? this.$t('management.orgSettings.newTitle') : this.$t('management.orgSettings.title')
        },
        showOnlySparkMessage() {
            return this.showAISetting && this.isSparkOrganization && this.hasStandardSubscription
        }
    },
    methods: {
        onAllowUploadModified(event) {
            this.$emit('allow-upload-modified', event)
        },
        onAllowGenerativeAIModified(event) {
            this.$emit('allow-generative-ai-modified', event)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../theming/main.scss";

.org-library-settings div {
    color: $pallete-dark-gray;
}

.title {
    font-size: 18px;
}

hr {
    border: none;
    height: 1px;
    background-color: $pallete-offwhite;
}

.message {
    font-size: 16px;
    &.disabled {
        color:  $theme-disabled;
    }
}
</style>
